define("discourse/plugins/swapd-auction/discourse/components/post-auction", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/utils", "@ember/runloop", "@ember/application"], function (_exports, _decorators, _ajax, _ajaxError, _component, _utils, _runloop, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const layout = Discourse.__container__.lookup("template:components/post-auction");
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["post-auction"],
    layout,
    loading: false,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    didInsertElement() {
      this._super(...arguments);
      this._updateTime(this);
      (0, _runloop.scheduleOnce)("afterRender", this, this._setup);
    },
    willDestroyElement() {
      clearInterval(this.intervalId);
      this._super(...arguments);
    },
    cantBid(can_bid, loading) {
      return !!!can_bid || loading;
    },
    hasEnded(timeDiff, closed) {
      return closed || (0, _utils.isBlank)(timeDiff) || timeDiff < 1;
    },
    showReserve(reserve_price, current_bid) {
      return !(0, _utils.isBlank)(reserve_price) && reserve_price > 0 && current_bid > 0;
    },
    reserveNotMet(showReserve, current_bid, reserve_price) {
      if (!showReserve) {
        return;
      }
      return current_bid < reserve_price;
    },
    showBuyNow(buy_now_price, current_bid) {
      return !(0, _utils.isBlank)(buy_now_price) && buy_now_price > 0 && buy_now_price > current_bid;
    },
    _setup() {
      const postWidth = $(".topic-body").width();
      $(".post-auction").css("width", postWidth + "px");
      const intervalId = setInterval(this._updateTime, 1000, this);
      this.set("intervalId", intervalId);
    },
    _updateTime(comp) {
      const diff = comp.end_date - moment().unix();
      comp.set("timeDiff", diff);
      if (diff < 0) {
        return;
      }
      const time = moment.duration(diff, "seconds");
      comp.setProperties({
        days: time.days(),
        hours: time.hours(),
        minutes: time.minutes(),
        seconds: time.seconds()
      });
    },
    _placeBid() {
      this.set("loading", true);
      (0, _ajax.ajax)(`/auction/${this.post_id}.json`, {
        type: "POST",
        data: {
          price: this.price,
          max_bid: this.max_bid
        },
        dataType: "json"
      }).catch(_ajaxError.popupAjaxError).then(r => {
        if (r.e) {
          const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: r.e
          });
        }
      }).finally(() => this.set("loading", false));
    },
    _buyNow() {
      this.set("loading", true);
      (0, _ajax.ajax)(`/auction/${this.post_id}/buy_now`, {
        type: "POST",
        data: {
          price: this.price,
          max_bid: this.max_bid
        },
        dataType: "json"
      }).catch(_ajaxError.popupAjaxError).then(r => {
        if (r.e) {
          const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: r.e
          });
        }
      }).finally(() => this.set("loading", false));
    },
    actions: {
      cancelMaxBid() {
        if (!this.your_max_bid || this.cancelingMaxBid) {
          return;
        }
        const self = this;
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.confirm({
          message: I18n.t("auction.cancel_max_bid_alert"),
          didConfirm: () => {
            this.set("cancelingMaxBid", true);
            (0, _ajax.ajax)(`/auction/${this.post_id}/cancel_max_bid`, {
              type: "POST"
            }).catch(_ajaxError.popupAjaxError).finally(() => this.set("cancelingMaxBid", false));
          }
        });
      },
      placeBid() {
        if (this.cantBid) {
          return;
        }
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        if ((0, _utils.isBlank)(this.price) || this.price < this.current_bid) {
          return dialog.alert({
            message: I18n.t("auction.less_than_current")
          });
        }
        if (this.price < this.current_bid + 10) {
          return dialog.alert({
            message: I18n.t("auction.min_increment")
          });
        }
        const self = this;
        const message = I18n.t("auction.place_bid_alert");
        dialog.confirm({
          message: message,
          didConfirm: () => {
            self._placeBid();
          }
        });
      },
      buyNow() {
        if (this.cantBid) {
          return;
        }
        const self = this;
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.confirm({
          message: I18n.t("auction.buy_now_alert"),
          didConfirm: () => {
            self._buyNow();
          }
        });
      }
    }
  }, [["method", "cantBid", [(0, _decorators.default)("can_bid", "loading")]], ["method", "hasEnded", [(0, _decorators.default)("timeDiff", "auction_closed")]], ["method", "showReserve", [(0, _decorators.default)("reserve_price", "current_bid")]], ["method", "reserveNotMet", [(0, _decorators.default)("showReserve", "current_bid", "reserve_price")]], ["method", "showBuyNow", [(0, _decorators.default)("buy_now_price", "current_bid")]]]));
});